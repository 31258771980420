import React from "react";
import PropTypes from "prop-types";
import Layout from "../../../../components/Layout/Layout";
import TagPage from "../../TagPage/TagPage";
import { graphql } from "gatsby";

const Tag = ({ data, pageContext, location }) => (
	<Layout pageContext={pageContext}>
		<TagPage data={data} location={location} pageContext={pageContext} />
	</Layout>
);

Tag.propTypes = {
	pageContext: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
		.isRequired,
	location: PropTypes.object.isRequired,
	data: PropTypes.shape({
		allGhostPost: PropTypes.object.isRequired,
		allGhostSettings: PropTypes.object.isRequired,
	}).isRequired,
};

export default Tag;

export const pageQuery = graphql`
	query($slug: String!, $limit: Int!, $skip: Int!, $lang: String!) {
		ghostTag(slug: { eq: $slug }) {
			slug
			name
			visibility
			feature_image
			description
			meta_title
			meta_description
		}
		allGhostPost(
			sort: { order: DESC, fields: [published_at] }
			filter: { tags: { elemMatch: { name: { eq: $lang }, slug: { eq: $slug } } } }
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					...GhostPostFields
				}
			}
		}
		allGhostSettings {
			edges {
				node {
					navigation {
						label
						url
					}
				}
			}
		}
	}
`;
