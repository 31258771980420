import React, { useContext } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Heading from "../../Heading/Heading";
import Description from "../../Description/Description";
import { readingTime } from "../../../helpers/helpers";
import { routes } from "../../../routes";
import { LocaleContext } from "../../Layout/Layout";

import "./PostCard.scss";

import AvatarSVG from "../../../assets/icons/avatar.svg";

const PostCard = ({
	post: {
		feature_image,
		tags,
		title,
		excerpt,
		primary_author: { profile_image, name },
		reading_time,
		slug,
	},
}) => {
	const { locale } = useContext(LocaleContext);

	return (
		<article className="postCard">
			<Link to={`${routes[locale].blog}/${slug}`} className="postCard__link" title={title}>
				<img src={feature_image} className="postCard__cover" alt={title} title={title} />
				<div className="postCard__tags">
					{tags.map(({ name }) =>
						!name.includes("#lang-") ? (
							<span key={name} className="postCard__tags--tag">
								{name}
							</span>
						) : null,
					)}
				</div>
				<Heading type={2} className="postCard__title">
					{title}
				</Heading>
				<Description className="postCard__description">{excerpt}</Description>
				<footer className="postCard__footer">
					<div className="postCard__footer--author">
						{profile_image ? (
							<img
								src={profile_image}
								title={name}
								alt={"author " + name}
								className="postCard__footer--author-image"
							/>
						) : (
							<AvatarSVG className="postCard__footer--author-image" />
						)}
						<span className="postCard__footer--author-name">{name}</span>
					</div>
					<span className="postCard__footer--read-time">{readingTime(reading_time, locale)}</span>
				</footer>
			</Link>
		</article>
	);
};

PostCard.propTypes = {
	post: PropTypes.shape({
		feature_image: PropTypes.string.isRequired,
		tags: PropTypes.arrayOf(PropTypes.object).isRequired,
		title: PropTypes.string.isRequired,
		excerpt: PropTypes.string.isRequired,
		primary_author: PropTypes.shape({
			profile_image: PropTypes.string,
			name: PropTypes.string.isRequired,
		}).isRequired,
		reading_time: PropTypes.number.isRequired,
		slug: PropTypes.string.isRequired,
	}).isRequired,
};

export default PostCard;
