import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import PostCard from "../../../components/Blog/PostCard/PostCard";

import { routes } from "../../../routes";
import useTranslations from "../../../hoc/useTranslations";
import { MetaData } from "../../../components/Blog/meta";
import Pagination from "../../../components/Pagination/Pagination";

const IndexPage = ({ data, locale, location, pageContext }) => {
	const {
		allGhostPost: { edges: posts },
		allGhostSettings: { edges: navigation },
		ghostTag,
	} = data;

	const { humanPageNumber } = pageContext;

	const {
		blog: {
			pagination: { page },
		},
	} = useTranslations();

	const isActive = label => {
		return ghostTag.name.replace(/\s/g, "") == label.replace("#", "");
	};

	return (
		<>
			<MetaData type="series" data={data} location={location} />
			<header className="blogHeader">
				<div className="blogHeader__tags">
					{navigation[0].node.navigation.map(({ label, url }) => (
						<Link
							className={isActive(label) ? "blogHeader__tags--tag active" : "blogHeader__tags--tag"}
							key={label}
							to={`${routes[locale].blog}${url}`}
							title={label}
						>
							{label}
						</Link>
					))}
				</div>
				<h1 className="blogHeader__heading">
					{humanPageNumber > 1 ? `${ghostTag.name} | ${page} - ${humanPageNumber}` : ghostTag.name}
				</h1>
			</header>
			<main>
				<section className="postCards">
					{posts.map(({ node }) => (
						<PostCard key={node.uuid} post={node} />
					))}
				</section>
			</main>
			<Pagination pageContext={pageContext} />
		</>
	);
};

IndexPage.propTypes = {
	data: PropTypes.shape({
		allGhostPost: PropTypes.object.isRequired,
		allGhostSettings: PropTypes.object.isRequired,
		ghostTag: PropTypes.shape({
			name: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	locale: PropTypes.string.isRequired,
	pageContext: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
		.isRequired,
	location: PropTypes.object.isRequired,
};

export default IndexPage;
